import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=317b7da7&scoped=true"
import script from "./AdminLayout.vue?vue&type=script&lang=js"
export * from "./AdminLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317b7da7",
  null
  
)

export default component.exports