<template>
  <div class="ps-vendor-dashboard">
    <div class="container-fluid">
      <ul class="ps-section__links">
        <li :class="{ active: $route.name === 'admin_dashboard' }">
          <router-link :to="{ name: 'admin_dashboard' }">Dashboard</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_users' }">
          <router-link :to="{ name: 'admin_users' }">Users</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_products' }">
          <router-link :to="{ name: 'admin_products' }">Products</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_orders' }">
          <router-link :to="{ name: 'admin_orders' }">Orders</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_payments' }">
          <router-link :to="{ name: 'admin_payments' }">Payments</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_shops' }">
          <router-link :to="{ name: 'admin_shops' }">Shops</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_currencies' }">
          <router-link :to="{ name: 'admin_currencies' }">Currencies</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_payment_methods' }">
          <router-link :to="{ name: 'admin_payment_methods' }"
            >Payment Methods</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'admin_delivery_methods' }">
          <router-link :to="{ name: 'admin_delivery_methods' }"
            >Delivery Methods</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'admin_runners' }">
          <router-link :to="{ name: 'admin_runners' }">Runners</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_vouchers' }">
          <router-link :to="{ name: 'admin_vouchers' }">Vouchers</router-link>
        </li>
        <li :class="{ active: $route.name === 'admin_settings' }">
          <router-link :to="{ name: 'admin_settings' }">Settings</router-link>
        </li>
        <li>
          <a href="#" @click.prevent="logout()">Logout</a>
        </li>
      </ul>
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminLayout",
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped></style>
